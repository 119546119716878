<modal-header [header]="'Report Name: ' + title | appTranslate | async" [dialogRef]="dialogRef"></modal-header>
<loader [loading]="loading">
  <div class="pt-0">
    <div class="row">
      <div class="col-lg-12 px-4">
        <ng-container *ngIf="data?.paginate === false; else tablePaginate">
          <table-plain [showAdditionalColumns]="true" [displayedColumns]="columns" [distinct]="true" [nowrap]="true"
            [smallerFonts]="true" [data]="rows" #tableTag [pageSize]="8" [showExport]="true" [showPager]="true"
            [useSelection]="false" [showFilter]="true"></table-plain>
        </ng-container>
        <ng-template #tablePaginate>
          <table-https [showAdditionalColumns]="true" [displayedColumns]="columns" [distinct]="true" [nowrap]="true"
            [smallerFonts]="true" #tableTag [pageSize]="8" [showExport]="true" [useSelection]="false"
            orderField="createdOn" [observableFunc]="observableFunc"></table-https>
        </ng-template>
      </div>
    </div>
  </div>
</loader>

<modal-comp [header]="innerTable.heading" width="90%" #innerTableModal>
  <div body>
    <loader [loading]="innerLoading">
      <table-plain
        [showAdditionalColumns]="true"
        [displayedColumns]="innerTable.columns"
        [distinct]="true"
        [nowrap]="true"
        [smallerFonts]="true"
        [data]="innerTable.data"
        #tableTag
        [pageSize]="8"
        [showExport]="true"
        [showPager]="true"
        [useSelection]="false"
        [showFilter]="true"></table-plain>
    </loader>
  </div>
</modal-comp>

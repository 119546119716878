import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IChartViewer } from './chart-viewer.interface';
import { NgChartDirective } from '../../../../../evolutics-shared-lib/src/lib/Shared/directives/ng-chart/ng-chart.directive';
import { NgStyle } from '@angular/common';
import { BtnComponent } from 'ets-fe-ng-sdk';
import { UtilityService } from '@Services/utility.service';
import { SalesAnalyticsComponent } from 'projects/evolutics-sales-ui/src/app/sales/analytics/analytics.component';

@Component({
  selector: 'app-chart-viewer',
  templateUrl: './chart-viewer.component.html',
  styleUrls: ['./chart-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgStyle, NgChartDirective, BtnComponent],
})
export class ChartViewerComponent implements OnInit {
  chart: IChartViewer;
  title: string;
  showAnalytic: boolean = false;
  constructor(
    private matDialog: MatDialogRef<ChartViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private uS: UtilityService
  ) {}

  ngOnInit(): void {
    this.chart = this.data.chart;
    this.showAnalytic = this.data.chart.showAnalytic;
    this.chart.options.scales.xAxes[0].ticks.fontSize = 10;
    this.title = this.data.title;
  }

  close() {
    this.matDialog.close();
  }

  showAnalytics() {
    this.uS.dialogOpener(
      SalesAnalyticsComponent,
      { width: '80%', height: '80%', data: {} },
      () => {}
    );
  }
}

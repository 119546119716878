<div class="card-header py-3" [ngStyle]="{ 'background-color': data.backgroundColor }">
  <h6 class="card-title">
    <span [ngStyle]="{ color: data.color }" class="title">{{ title }}</span>
    <span (click)="close()" style="float: right"><i class="fa fa-close close" aria-hidden="true"></i></span>
  </h6>
</div>
<div class="p-5">
  <canvas
    ngChart
    [options]="chart?.options"
    [plugins]="chart?.plugins"
    [datasets]="chart?.data?.datasets"
    [labels]="chart?.data?.labels"
    [chartType]="chart?.type"
    [legend]="true"></canvas>
</div>

@if (showAnalytic) {
  <div class="row justify-content-end">
    <div class="col-md-auto">
      <app-btn text="Analytics" icon="show" (mclick)="showAnalytics()"></app-btn>
    </div>
  </div>
}

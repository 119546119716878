import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilityService } from '@Services/utility.service';
import { TableViewerComponent } from '../table-viewer/table-viewer.component';
import { NgIf, NgStyle } from '@angular/common';
import { BtnComponent } from 'ets-fe-ng-sdk';

@Component({
  selector: 'app-card-box-viewer',
  templateUrl: './card-box-viewer.component.html',
  styleUrls: ['./card-box-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgStyle, BtnComponent, NgIf],
})
export class CardBoxViewerComponent implements OnInit {
  card: any;
  raLoading: boolean;
  constructor(
    private uS: UtilityService,
    private matDialog: MatDialogRef<CardBoxViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.card = this.data;
  }

  close() {
    this.matDialog.close();
  }

  viewData(data) {
    this.uS.dialogOpener(
      TableViewerComponent,
      {
        minWidth: '50%',
        minHeight: '50%',
        data: {
          title: data.tableTitle,
          headings: data.headings,
          rows: data.rows,
          color: data.color,
          backgroundColor: data.backgroundColor,
        },
      },
      () => {},
    );
  }
}

<div class="p-5">
  <canvas
    ngChart
    [options]="chart?.options"
    [datasets]="chart?.data?.datasets"
    [labels]="chart?.data?.labels"
    [chartType]="chart?.type"
  ></canvas>
</div>
<div>
  <p class="chart-footer">
    Production Level
    <b [ngStyle]="{ color: chart.color }">(April 2022)</b>
  </p>
</div>

<modal-header header="Showing Analytics" [dialogRef]="dialogRef"></modal-header>
<loader [loading]="loading">
  <div class="p-4">
    <!-- <div class="row justify-content-between">
    <div class="col-md-4">
      <app-autocomplete label="Team Code" [options]="teamCodes" labelType="cd" valueField="code" [form]="form"
        name="teamCode"></app-autocomplete>
    </div>
    <div class="col-md-auto">
      <app-btn text="Show Chart" (mclick)="show" icon="show" type="primary"></app-btn>
    </div>
  </div> -->
    @if(showChart) {
    <div class="container">
      <h6 class="text-primary text-bold">{{ 'Team Chart' }}</h6>
      <canvas ngChart class="spacing" [options]="chart?.options" [plugins]="chart?.plugins" [datasets]="chart?.data?.datasets"
        [labels]="chart?.data?.labels" [chartType]="chart?.type" [legend]="false"></canvas>
      <div class="row mt-2">
        <div class="col-md-auto">
          <p>{{'Opportunity Conversion Ratio: ' + teamData.opportunityConversionRatioTeam | appTranslate | async
            }}</p>
        </div>
        <div class="col-md-auto">
          <p>{{'Prospect Conversion Ratio: ' + teamData.prospectConversionRatioTeam | appTranslate | async
            }}</p>
        </div>
      </div>
    </div>
    <div class="container mt-4">
      <h6 class="text-primary text-bold">{{ 'Your Chart' }}</h6>
      <canvas ngChart class="spacing" [options]="chart?.options" [plugins]="chart?.plugins" [datasets]="chart2?.data?.datasets"
        [labels]="chart?.data?.labels" [chartType]="chart?.type" [legend]="false"></canvas>
      <div class="row mt-2">
        <div class="col-md-auto">
          <p>{{'Opportunity Conversion Ratio: ' + memberData.opportunityConversionRatioTeam | appTranslate | async
            }}</p>
        </div>
        <div class="col-md-auto">
          <p>{{'Prospect Conversion Ratio: ' + memberData.prospectConversionRatioTeam | appTranslate | async
            }}</p>
        </div>
      </div>
    </div>
    }
  </div>
</loader>

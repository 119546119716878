<div class="col-lg-12">
  <div class="card">
    <div class="card-header py-3" [ngStyle]="{ 'background-color': card.backgroundColor }">
      <h6 class="card-title">
        <span [ngStyle]="{ color: card.color }" class="title">
          {{ card.title }}
        </span>
        <span (click)="close()" style="float: right">
          <i class="fa fa-close close" aria-hidden="true"></i>
        </span>
      </h6>
    </div>
    <div class="card-body">
      <div class="row">
        @for (c of card.data; track c) {
          <div class="col-lg-6 cardo">
            <div [ngStyle]="{ 'background-color': card.color }" class="card-box" (click)="viewData(c)">
              <p>{{ c?.title }}</p>
              <h2>{{ c?.value }}</h2>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</div>
